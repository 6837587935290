.admin{
    margin-top: 76px;
    background-color: #153A81;
}

.directory-container{
    margin-bottom: 0 !important;
}

.side-nav{
    margin-top:1% !important;
    
    padding:2% !important;
    border-radius: 15px;
    background-color: rgb(16%, 56%, 89%);
    -moz-box-shadow: 3px 0 3px 0 #000000;
    -webkit-box-shadow: 3px 0 3px 0 #000000;
    box-shadow: 3px 0 3px 0 #000000;
}

.side-nav a{
    color: white;
}

.sidenav-item{
    height: 3.5rem !important;
    padding-top: 1rem !important;
    text-align: center !important;
    width: 100%;
    border-bottom: 1px solid white;
    color:white;
    font-weight: 700;
}

.sidenav-item:hover{
    border-bottom: 2px solid yellow;
    color:yellow !important;
}


.directory-window{
    padding:0 !important;
}

.job-table{
    height: 86.25vh;
    background-color: white;
    overflow-y: scroll;
    padding: 1%;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    margin-top:1%;
    margin-left: 2%;
    margin-right:2%;
    margin-bottom: 1%;
}

.applicant-table{
    height: 86.25vh;
    background-color: white;
    overflow-y: scroll;
    padding: 1%;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    margin-top:1%;
    margin-left: 2%;
    margin-right:2%;
    margin-bottom: 1%;
}

.applicant-table-small{
    height: 50vh;
    background-color: white;
    overflow-y: scroll;
    padding: 1%;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    margin-top:1%;
    margin-left: 2%;
    margin-right:2%;
    margin-bottom: 1%;
}

.candidate-view{
    margin-top: 76px;
    background-color: #153A81;
    height:89.25vh;
}

.candidate-content{
    height:89.25vh;
    box-shadow: 9px 10px 15px -4px rgba(0, 0, 0, 0.5);
    background-color: white;
}

.login-container{
    height: 89.25vh;
}

.login{
    height: 45vh;
    background-color: white;
    margin-top: 10% !important;
    margin-left: 25% !important;
    border-radius: 25px;
    padding-left:8% !important;
    padding-right: 8% !important;
    padding-top: 6% !important;
}



.keyword-search{
    border: 0px solid;
    border-bottom: 1px solid white !important;
    color:white !important;
    background-color: transparent !important;
    
}

.search-filter{
    padding: 0 !important;
    margin-bottom: 4%;
}

.search-label{
    color: white !important;
}

#search-filter{
    display: flex !important;
    padding:0 !important;
}