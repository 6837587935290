.edit-btn{
    margin-right:1% !important;
}

.delete-btn:hover{
    background-color: red !important;
}

.btn-row{
    margin-top: 10px;
}

.candidates{
    margin-top: 3%;
}