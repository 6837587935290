.home-page{
    margin-top: 76px;
  
}

.title-container{
    height:0px;
    position: relative;
    top:80px;
    margin-left: 10%;
    width:80%;
}


.title-head{
    margin-top: 0;
    color:#ffffff;
    font-size: 5rem;
    font-weight: 700;
}

.title-background{
    vertical-align: top;
    border-radius: 25px;
    padding:4%;
    padding-left:3%;
    padding-right:3%;
    background-color: rgba(0, 0, 0, 0.5);
}

.title-motto{
    color: white;
    font-size: 2rem;
    width: 100%;
    font-weight: 600;
    
}

.title-btn{
    margin-left:0;
    margin-right:4%;
    background-color: rgb(16%, 56%, 89%) !important;
}

.parallax-container{
    height: 89.25vh;
    width:100%;
}

.parallax-img{
    filter: brightness(50%)
}

.specialties{
    padding-bottom: 2%;
}

.specialties-desc{
    color: #808080;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 2%;
}

.specialties h1{
    color:#153A81;
    margin-bottom: 1%;
}

.specialties a{
    color: inherit;
}

.specialties a:hover{
    color:  rgb(16%, 56%, 89%);
}

.icon-img img{
    height: 200px;
    margin-bottom: 4%;
}

.testimonials h3{
    color: #153A81;
    margin-top: 5%;
    padding-bottom: 4%;
}

.t1{
    background-image: url("./assets/Engineers.png");
    background-size: 100% auto;
}

.t2{
    background-image: url("./assets/welder.png");
    background-size: 100% auto;
}

.t3{
    background-image: url("./assets/Industrial\ Maintenance.jpg");
    background-size: 100% auto;
}

.testimonial-background{
    vertical-align: top;
    padding:4%;
    padding-left:10%;
    padding-right:10%;
    background-color: rgba(0, 0, 0, 0.65);
    height:100%
}

.page-footer{
    background-color: rgb(16%, 56%, 89%);
}

.footer-copyright{
    background-color: #153A81 !important;
}

.page-footer h4{
    margin-top: 3%;
    margin-bottom: 3%;
}

@media (max-width: 800px){
    .t1{
    background-image: url("./assets/Engineers.png");
    background-size: 100% 100%;
    }

    .t2{
    background-image: url("./assets/welder.png");
    background-size: 100% 100%;
    }

    .t3{
    background-image: url("./assets/Industrial\ Maintenance.jpg");
    background-size: 100% 100%;
    }
}

@media (max-width: 600px){
.title-container{
    height:0px;
    position: relative;
    top:0px;
    margin-left:0%;
    width:100%;
    }


    .title-head{
        margin-top: 0;
        color:#ffffff;
        font-size: 5rem;
        font-weight: 700;
    }

    .title-background{
        width: 100%;
        vertical-align: top;
        border-radius: 25px;
        padding:4%;
        padding-left:3%;
        padding-right:3%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .title-motto{
        color: white;
        font-size: 2rem;
        width: 100%;
        font-weight: 600;
        
    }
}