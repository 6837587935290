.job-search-apply{
    margin-top: 76px;
    background-color: #153A81;
   
}

.search{
    margin-bottom: 0 !important;
    padding-bottom: 3%;
}

.search-feature{
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
}

.search-bars{
    padding-left:0 !important;
}

.jsa-content{
    box-shadow: 9px 10px 15px -4px rgba(0, 0, 0, 0.5);
    background-color: white;
}

.job-search-table{
    padding:0 !important;
    height: 80vh;
    border: 1px solid gray;
    border-radius: 20px;
    overflow-y: scroll;
}

.job-title-head{
    width: 30% !important;
}

@media (max-width: 600px){
    .jsa-content{
        box-shadow: 9px 10px 15px -4px rgba(0, 0, 0, 0.5);
        background-color: white;
        margin:0 !important;
        width: 100% !important;
    }
}