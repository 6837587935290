.addJobPage{
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #153A81 !important;
}

.addJob-fields{
    background-color: white !important;
    border-radius: 25px;
    padding-top: .5rem;
    padding-bottom: 2rem;
    margin-left: 15% !important;
}

.job-inputs{
    margin-left: 8.33% !important;
}