.contact-form{
    margin-top:8%;
    margin-bottom:8%;
}

.submit-button{
    float: right;
    background-color:rgb(16%, 56%, 89%);
}

.submit-button:hover{
    background-color: #153A81 !important;
}