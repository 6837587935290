.multi-field{
    margin-top: 0.75rem !important;
}
.field-container{
    padding-left:0 !important;
    padding-top: .75rem;
    margin-top:1rem;
}
.remove-btn{
    margin-top: 1rem;
}

.multi-title{
    padding: 0 !important;
}