.apply-success{
    padding-left:5%;
    padding-top: 1%;
    padding-right: 5%;
    height: 80vh;
}

.thanks{
    margin-bottom: 0 !important;
}

.instructions{
    margin-bottom: 0!important;
    padding-bottom: 1%;
    padding-top: 1%;
}